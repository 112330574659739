/* DesignPage.css */

.design-banner {
    background-color: rgb(131, 207, 202);
    color: #f7f7f3;
    padding: 10px;
    text-align: center;
    font-size: 30px;
    width: 100%;
    margin-bottom: 30px;
    margin-top: 5px;
}

.design-project h2 {
    background-color: rgb(131, 207, 202);
    color: #f7f7f3;
    padding: 10px 20px;
    text-align: center;
    font-size: 25px;
    width: 95%;
    margin: 0 auto;
    border-radius: 8px;
}

.design-project p {
    text-align: center;
    font-size: 20px;
    width: 95%;
    margin: 0 auto;
    /* Center the paragraph within the project */
}

.design-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.design-project {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* Ensure project spans the full width of its parent */
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 20px;
    box-sizing: border-box;
    /* Include padding and border in the element's total width */
}

.images-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

.image-wrapper {
    width: calc(33.333% - 10px);
    height: 400px;
    margin: 5px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.design-project img {
    max-width: 100%;
    /* Ensure image does not exceed its container's width */
    height: 100%;
    /* Adjusted to fill the increased height of the wrapper */
    object-fit: cover;
    /* Cover the space without distorting aspect ratio */
}

.design-button {
    display: inline-block;
    background-color: #f7f7f3;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 4px;
    margin: 10px 20px;
}

/* Styles for the Schedule section */
.schedule-section {
    background-color: rgb(131, 207, 202);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    padding: 20px;
    text-align: center;
}

.schedule-section p {
    color: #f7f7f3;
    margin-bottom: 10px;
    margin: 0;
    padding: 5px;
}

.schedule-section button {
    background-color: transparent;
    color: rgb(131, 207, 202);
    padding: 10px 15px;
    border: 2px solid #f7f7f3;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 auto;
    white-space: normal;
    transition: background-color 0.3s, padding 0.3s;
    width: 100%;
    max-width: 300px;
}

/* Responsive font sizes */
@media (max-width: 767px) {
    .schedule-section p {
        font-size: 5vw;
        /* Responsive font size for mobile */
    }

    .schedule-section button {
        font-size: 5vw;
        /* Responsive font size for mobile */
    }
}

@media (min-width: 768px) {
    .schedule-section p {
        font-size: 30px;
        /* Adjusted font size for desktop */
    }

    .schedule-section button {
        font-size: 30px;
        /* Adjusted font size for desktop */
        max-width: 400px;
        /* Adjust button width for desktop */
    }
}

.expand-button {
    display: block;
    margin: 10px auto;
    padding: 8px 12px;
    font-size: 14px;
    cursor: pointer;
    border: none;
    background-color: rgb(131, 207, 202);
    color: white;
    border-radius: 5px;
    transition: background 0.3s;
}

.expand-button:hover {
    background-color: #555;
}


