.real-estate-banner {
  background-color: rgb(131, 207, 202);
  color: #f7f7f3;
  padding: 10px;
  text-align: center;
  font-size: 30px;
  width: 100%;
  margin-top: 5px;
}

.real-estate-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 0;
  margin: 0;
}

.selected-listing {
  position: relative;
  /* ✅ Ensures status banner positions correctly */
  width: 100%;
  max-width: 1200px;
  margin: 20px 0;
  padding: 20px;
  border: 2px solid #f7f7f3;
  border-radius: 5px;
  background-color: #f1f1f1;
}

.listing-images {
  margin-bottom: 20px;
}

.featured-image {
  width: 100%;
  max-height: 500px;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
}

.thumbnail-gallery {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  overflow-x: auto;
}

.thumbnail-image {
  cursor: pointer;
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}

/* 🏡 ✅ Fixed: Properly Sized Thumbnails */
.listing-thumbnail img {
  width: 100%;
  height: 150px;
  /* ✅ Ensures thumbnails are properly sized */
  object-fit: cover;
  border-radius: 5px;
}

.listing-thumbnails {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  width: 100%;
}

.listing-thumbnail {
  position: relative;
  cursor: pointer;
  width: 200px;
  border: 2px solid rgb(131, 207, 202);
  border-radius: 5px;
  padding: 10px;
}

/* 🏡 Status Banner for Selected Listing */
.selected-status {
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 12px 16px;
  font-size: 18px;
  font-weight: bold;
  color: white;
  border-radius: 8px;
  text-transform: uppercase;
  z-index: 10;
}

/* ✅ Status Colors for Selected Listing */
.selected-status.for-sale {
  background-color: #28a745;
}

/* Green */
.selected-status.pending-sale {
  background-color: #ffc107;
  color: black;
}

/* Yellow */
.selected-status.under-contract {
  background-color: #007bff;
}

/* Blue */
.selected-status.sold {
  background-color: #dc3545;
}

/* Red */

/* 🏡 Status Banner for Listing Thumbnails */
.status-banner {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  border-radius: 5px;
  text-transform: uppercase;
  z-index: 10;
}

/* ✅ Status Colors for Thumbnails */
.status-banner.for-sale {
  background-color: #28a745;
}

/* Green */
.status-banner.pending-sale {
  background-color: #ffc107;
  color: black;
}

/* Yellow */
.status-banner.under-contract {
  background-color: #007bff;
}

/* Blue */
.status-banner.sold {
  background-color: #dc3545;
}

/* Red */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}

.modal-content img {
  width: 100%;
  height: auto;
  max-height: 80vh;
}

.modal-controls {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
}

.modal-controls button {
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
}

.modal-controls button:hover {
  background-color: rgba(255, 255, 255, 0.9);
}

.listing-status {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
}

.listing-status span {
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
}

.listing-status span.for-sale {
  background-color: green;
}

.listing-status span.under-contract {
  background-color: orange;
}

.listing-status span.pending-sale {
  background-color: yellow;
  color: black;
}

.listing-status span.sold {
  background-color: red;
}

/* 🔥 No changes to listing details */
.listing-info-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.listing-info-item {
  flex: 1 1 45%;
  margin-bottom: 10px;
}

.listing-info-item strong {
  font-weight: bold;
}

.listing-details {
  margin-top: 20px;
}

.dropzone {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
  cursor: pointer;
}

.preview-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.preview-images img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}

.listing-actions {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
}

.create-listing-btn,
.delete-listing-btn {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s ease;
}

.create-listing-btn {
  background-color: rgb(131, 207, 202);
  color: white;
  border: none;
}

.create-listing-btn:hover {
  background-color: rgb(18, 174, 174);
}

.delete-listing-btn {
  background-color: red;
  color: white;
  border: none;
}

.delete-listing-btn:hover {
  background-color: darkred;
}

.modal-content.create-listing-modal {
  max-height: 80vh;
  overflow-y: auto;
  padding-bottom: 20px;
}

/* ✅ Request Showing Button - Original Styling */
.request-showing-btn {
  display: block;
  text-align: center;
  margin: 20px auto 0;
  padding: 15px 25px;
  background-color: rgb(131, 207, 202);
  color: #f7f7f3;
  border-radius: 5px;
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 250px;
}

.request-showing-btn:hover {
  background-color: rgb(18, 174, 174);
  transform: translateY(-3px);
}