/* ✅ Edit & Create Listing Page */
.create-edit-listing-page {
    max-width: 600px;
    margin: 40px auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* 🏡 Styling for Input Fields */
.listing-form input,
.listing-form textarea,
.listing-form select {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

/* ✅ Make Textarea More Usable */
.listing-form textarea {
    resize: vertical;
    height: 120px;
}

/* 🏡 Drag & Drop Image Upload */
.dropzone {
    border: 2px dashed #aaa;
    padding: 20px;
    text-align: center;
    margin-top: 15px;
    cursor: pointer;
    background: #f5f5f5;
    transition: 0.3s ease-in-out;
}

.dropzone:hover {
    background: #e0e0e0;
}

/* 🖼️ Preview Images */
.image-preview {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
}

/* 🏡 Image Preview Container */
.image-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* ✅ Ensure Images are Displayed Properly */
.image-item img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid #ccc;
}

/* ❌ Remove Button */
.image-item button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: red;
    color: white;
    border: none;
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
}

/* ✅ Save Button */
.save-listing-btn {
    width: 100%;
    padding: 12px;
    background: green;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.save-listing-btn:hover {
    background: darkgreen;
}

/* 🏡 Responsive Design for Mobile */
@media (max-width: 768px) {
    .create-edit-listing-page {
        width: 90%;
    }

    .image-item img {
        width: 80px;
        height: 80px;
    }

    .save-listing-btn {
        font-size: 16px;
        padding: 10px;
    }
}