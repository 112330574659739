.fullscreen-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fullscreen-image {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
}

.close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 24px;
    background: none;
    color: white;
    border: none;
    cursor: pointer;
}

.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 36px;
    background: none;
    color: white;
    border: none;
    cursor: pointer;
}

.left {
    left: 20px;
}

.right {
    right: 20px;
}

.arrow:hover,
.close-button:hover {
    background: rgba(255, 255, 255, 0.8);
}